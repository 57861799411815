

























import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { AlertDialogProperties } from "@/components/shared/AlertDialogView.vue";
import { LiveSessionInfoProperties } from "./CommonLiveSessionProperties";

@Component({})
export default class LiveSessionVideoView extends Vue {
    @Prop() private properties: LiveSessionInfoProperties;
    @Prop() private alertDialog: AlertDialogProperties;
    private container: HTMLElement;
    private dialog: any = {};

    public get popupVideo() {
        return this.properties.popupVideo;
    }

    public created() {
        this.properties.events.subscribe("loaded", this.loadVideo);
        document.addEventListener("mousemove", this.dialogMouseMove);
        document.addEventListener("mouseup", this.dialogMouseUp);
    }

    public destroyed() {
        this.properties.events.remove("loaded", this.loadVideo);
        document.removeEventListener("mousemove", this.dialogMouseMove);
        document.removeEventListener("mouseup", this.dialogMouseUp);
    }

    public mounted() {
        this.container = document.getElementById("video");
        this.dialog.card = document.getElementById("video-card");
        this.dialog.resizer = document.getElementById("video-resizer");
        this.dialog.cardTitle = document.getElementById("video-card-title");
        this.dialog.resizer.addEventListener("mousedown", this.dialogBeginResize);
        this.dialog.cardTitle.addEventListener("mousedown", this.dialogBeginMove);

        this.adjustVideoSize();
        this.loadVideo();
    }

    public switchPopup() {
        Vue.set(this.properties, "popupVideo", !this.popupVideo);

        if (!this.popupVideo) {
            this.dialog.card.style.width = "";
            this.dialog.card.style.height = "";
            this.dialog.card.style.left = "";
            this.dialog.card.style.top = "";
        }

        this.adjustVideoSize();
    }

    public capturePosition(e) {
        const rect = this.dialog.card.getBoundingClientRect();
        this.dialog.mouseX = e.clientX;
        this.dialog.mouseY = e.clientY;
        this.dialog.cardX = rect.left;
        this.dialog.cardY = rect.top;
    }

    public dialogBeginResize(e) {
        if (e.button === 0) {
            this.capturePosition(e);
            this.dialog.cardWidth = this.dialog.card.clientWidth;
            this.dialog.cardHeight = this.dialog.card.clientHeight;

            if (this.dialog.iframe) {
                this.dialog.iframe.style.pointerEvents = "none";
            }
            this.dialog.resize = true;
        }
    }

    public dialogBeginMove(e) {
        if (e.button === 0) {
            this.capturePosition(e);

            if (this.dialog.iframe) {
                this.dialog.iframe.style.pointerEvents = "none";
            }

            this.dialog.moving = true;
        }
    }

    public dialogMouseMove(e) {
        if (this.dialog.moving) {
            e.preventDefault();
            const leftPositionX = Math.max(this.dialog.cardX + e.clientX - this.dialog.mouseX, 0);
            const rightPositionX = window.innerWidth - this.dialog.card.clientWidth;
            const positionX = Math.min(leftPositionX, rightPositionX);

            const topPositionY = Math.max(this.dialog.cardY + e.clientY - this.dialog.mouseY, 0);
            const bottomPositionY = window.innerHeight - this.dialog.card.clientHeight;
            const positionY = Math.min(topPositionY, bottomPositionY);

            this.dialog.card.style.left = positionX + "px";
            this.dialog.card.style.top = positionY + "px";
        } else if (this.dialog.resize) {
            e.preventDefault();
            const ratio = 1280 / 720;
            var width = this.dialog.cardWidth + e.clientX - this.dialog.mouseX;
            if (width < 250) {
                width = 250;
            }
            if (width + this.dialog.cardX > window.innerWidth) {
                width = window.innerWidth - this.dialog.cardX;
            }

            var videoHeight = width / ratio;
            var height = videoHeight + this.dialog.cardTitle.clientHeight;

            if (height + this.dialog.cardY > window.innerHeight) {
                height = window.innerHeight - this.dialog.cardY;
                videoHeight = height - this.dialog.cardTitle.clientHeight;
                width = videoHeight * ratio;
            }

            this.dialog.card.style.width = width + "px";
            this.dialog.card.style.height = height + "px";
            this.container.style.width = width + "px";
            this.container.style.height = videoHeight + "px";
        }
    }

    public dialogMouseUp(e) {
        this.dialog.moving = false;
        this.dialog.resize = false;
        if (this.dialog.iframe) {
            this.dialog.iframe.style.pointerEvents = "";
        }
    }

    @Watch("properties.primaryWidth")
    public adjustVideoSize() {
        if (!this.popupVideo && this.container) {
            const ratio = 1280 / 720;
            const width = this.properties.primaryWidth - (this.properties.primaryWidth * 30) / 100;
            const height = width / ratio;

            this.container.style.width = width + "px";
            this.container.style.height = height + "px";
        }
    }

    public loadVideo() {
        if (this.container) {
            delete this.dialog.iframe;
            this.container.innerHTML = "";

            const template = document.createElement("template");
            template.innerHTML = this.properties.liveSession.embeddedVideo;

            if (!template.content.firstChild) return;
            const iframe = <HTMLIFrameElement>template.content.firstChild;
            if (iframe.src) {
                const url = new URL(iframe.src);
                url.searchParams.delete("width");
                url.searchParams.append("height", "280");

                iframe.src = url.toString();
                iframe.height = iframe.width = "";
                iframe.style.width = "100%";
                iframe.style.height = "100%";

                this.container.appendChild(iframe);
                this.dialog.iframe = iframe;
            }
        }
    }
}
